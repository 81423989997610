<template>
  <div class="fast-body-modo-aluno">
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container fast-plataforma-iuea-hide">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide mb-0">
            <li class="fd-app-breadcrumb-item">
              <a href="/minhas-plataformas" class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')">Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a :href="'/plataforma/' + $route.params.id_plataforma" class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)">Plataforma {{
                  $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')">Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria')">Secretaria</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Meus Requerimentos</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'">Menu
                principal</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a class="aluno_font_color" href="#">Meus Requerimentos</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-ead-content mt-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="mt-3 mb-4">
                <a class="btn-novo btn-red-hollow-novo"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria')">
                  <b-icon-arrow-return-left /> Voltar
                </a>
              </div>
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome-novo text-left fast-plataforma-iuea-hide">
                <h2 class="aluno_font_color mb-0">
                  Meus <span>Requerimentos</span>
                </h2>
                <p class="aluno_font_color my-0">Acompanhe seus requerimentos!</p>
                <div>
                  <img :src="require('@/assets/images/separador.png')
                    ">
                </div>
              </section>
              <!-- /fd-app-welcome -->
              <section class="mt-3">
                <!-- Conteúdo principal -->
                <div class="card-body px-0">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 text-left mb-4">
                      <a href="#" class="btn-novo btn-blue-hollow p-2" @click.prevent="
                        showModal(
                          'modalSelecionaTipoRequerimento'
                        )
                        ">
                        <b-icon-plus-circle />
                        Solicitar novo requerimento
                      </a>
                    </div>
                  </div>
                  <h5 class="text-secondary mb-3">FILTRO</h5>
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                      <select v-model="fastFiltro.nomeRequerimentoEscolhido
                        " class="form-control" @change="aplicaFiltro()">
                        <option value="">
                          -- Selecione um tipo do Requerimento --
                        </option>
                        <option v-for="nome in fastFiltro.nomeRequerimento" :key="nome" :value="nome">
                          {{ nome }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                      <select v-model="fastFiltro.statusRequerimentoEscolhido
                        " class="form-control" @change="aplicaFiltro()">
                        <option value="">
                          -- Selecione o status --
                        </option>
                        <option v-for="status in fastFiltro.statusRequerimento" :key="status" :value="status">
                          <span v-if="status == 'S'">
                            Aprovado</span>
                          <span v-else> Pendente</span>
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mt-1">
                      <a href="#" class="btn-novo btn btn-orange-hollow" @click="limparFiltro()">
                        <small>Limpa Filtros</small>
                      </a>
                    </div>
                  </div>

                  <div class="fd-app-table-novo col-12 p-0 mt-4">
                    <div class="col-12">
                      <h5 class="mb-0 pl-3 py-1">Lista de Requerimentos</h5>
                    </div>
                    <div class="col-12 table-responsive m-0 p-0">
                      <table class="table table-sm text-nowrap">
                        <thead>
                          <tr class="text-center">
                            <th>
                              <small class="font-weight-bold">Nome do requerimento</small>
                            </th>
                            <th>
                              <small class="font-weight-bold">Pago</small>
                            </th>
                            <th>
                              <small class="font-weight-bold">Aprovado</small>
                            </th>
                            <th>
                              <small class="font-weight-bold">Valor</small>
                            </th>
                            <th>
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastRequerimentosLoading">
                          <tr>
                            <td colspan="5" class="text-center">
                              Carregando requerimentos...
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else-if="fastRequerimentosTotal.length">
                          <tr v-for="requerimento in fastRequerimentosPagination" :key="requerimento.id_registro_requerimento
                            ">
                            <td class="align-middle text-center">
                              {{ requerimento.nome_requerimento }}
                            </td>
                            <td class="align-middle text-center">
                              <small v-if="requerimento.pago == 'S'" class="text-success">
                                PAGO</small>
                              <small v-else class="text-primary">
                                PENDENTE</small>
                            </td>
                            <td class="align-middle text-center">
                              <small v-if="requerimento.aprovada == 'S'" class="text-success">
                                APROVADO</small>
                              <small v-else class="text-primary">
                                PENDENTE</small>
                            </td>

                            <td class="align-middle text-center">
                              <span v-if="requerimento.preco_servico">R$
                                {{
                                  requerimento.preco_servico.toLocaleString(
                                    "pt-br",
                                    { minimumFractionDigits: 2 }
                                  )
                                }}</span>
                            </td>
                            <td class="align-middle text-center">
                              <a href="#" v-if="requerimento.aprovada != 'S'"
                                class="btn-novo btn-sm btn-primary ml-1 mr-1"
                                @click="exibeModalEditarRequerimento(requerimento)">
                                <small class="text-light">Editar</small>
                              </a>
                              <a href="#" class="btn-novo btn-sm btn-primary ml-1 mr-1" @click="
                                exibeModalDetalhesRequerimento(
                                  requerimento
                                )
                                ">
                                <small class="text-light">Ver detalhes</small>
                              </a>
                              <a href="#" v-if="requerimento.aprovada != 'S'"
                                class="btn-novo btn-sm btn-primary ml-1 mr-1"
                                @click="exibeModalExcluirRequerimento(requerimento)">
                                <small class="text-light">Excluir</small>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="5" class="text-center" style="color:#008BD0;">
                              Nenhum requerimento cadastrado
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center my-2">
                      <Pagination :page-size="10" :items="fastRequerimentoFiltro" @changePage="
                        fastRequerimentosPagination = $event
                        " />
                    </div>
                  </div>
                </div>
                <!-- /Conteúdo principal -->
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- modal -->
    <modal name="exibeModalDetalhesRequerimento" :shift-y="0.1" height="auto" :width="modalWidthMd" :scrollable="true"
      :adaptative="true">
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Detalhes do Requerimento</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a class="btn btn-secondary" href="#" @click.prevent="hideModal('exibeModalDetalhesRequerimento')">
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="container border">
                <div class="row border">
                  <div class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Aprovado:</b></small>
                    <br>
                    <small v-if="fastRequerimentoDetalhes.aprovada == 'S'">
                      Sim</small>
                    <small v-else> Pendente</small>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Status Boleto:</b></small>
                    <br>
                    <small v-if="!fastRequerimentoDetalhes.boleto_gerado">
                      Boleto não gerado.</small>
                    <small v-else> Boleto Gerado</small>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Data de Aprovação</b></small>
                    <br>
                    <small v-if="!fastRequerimentoDetalhes.data_aprovacao">Aprovação Pendente</small>
                    <small v-else>
                      {{ fastRequerimentoDetalhes.data_aprovacao }}</small>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Data de Pagamento</b></small>
                    <br>
                    <small v-if="!fastRequerimentoDetalhes.data_pagto">
                      Pagamento Pendente</small>
                    <small v-else>
                      {{ fastRequerimentoDetalhes.data_pagto }}</small>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Detalhes do Requerimento</b></small>
                    <br>
                    <small v-if="!fastRequerimentoDetalhes.detalhe_requerimento">
                      Sem Detalhes</small>
                    <small v-else>
                      {{ fastRequerimentoDetalhes.detalhe_requerimento }}</small>
                  </div>
                  <div v-if="fastRequerimentoDetalhes.total_requerimento" class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Total do requerimento</b></small>
                    <br>
                    <small>R$
                      {{
                        fastRequerimentoDetalhes.total_requerimento.toLocaleString(
                          "pt-br",
                          { minimumFractionDigits: 2 }
                        )
                      }}</small>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Nome do Requerimento</b></small>
                    <br>
                    <small>{{
                      fastRequerimentoDetalhes.nome_requerimento
                    }}</small>
                  </div>
                  <div v-if="fastRequerimentoDetalhes.obs" class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Obs:</b></small>
                    <br>
                    <small>{{ fastRequerimentoDetalhes.obs }}</small>
                  </div>
                  <div v-if="fastRequerimentoDetalhes.obs_aprovador" class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Obs aprovação:</b></small>
                    <br>
                    <small>{{ fastRequerimentoDetalhes.obs_aprovador }}</small>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Detalhe Pagamento</b></small>
                    <br>
                    <small v-if="!fastRequerimentoDetalhes.pago">
                      PENDENTE</small>
                    <small v-else> {{ fastRequerimentoDetalhes.pago }}</small>
                  </div>
                  <div v-if="fastRequerimentoDetalhes.path_boleto" class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Link do Boleto</b></small>
                    <br>
                    <a :href="fastRequerimentoDetalhes.path_boleto" target="_blank">Abrir Boleto</a>
                  </div>
                  <div v-if="fastRequerimentoDetalhes.path_anexo" class="col-sm-12 col-md-12 col-lg-4 border">
                    <small><b>Link do anexo</b></small>
                    <br>
                    <a :href="fastRequerimentoDetalhes.path_anexo" target="_blank">Abrir anexo</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modalSelecionaTipoRequerimento" :shift-y="0.1" height="auto" :width="modalWidthSm" :scrollable="true"
      :adaptative="true">
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Tipo de requerimento</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalSelecionaTipoRequerimento')">
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <select class="custom-select custom-select-md" @change="validaTipoRequerimento">
                <option value="">
                  -- Selecione um tipo de requerimento --
                </option>
                <option v-for="req in fastTipoRequerimentos" :key="req.id_tipo_requerimento"
                  :value="req.id_tipo_requerimento">
                  {{ req.nome_requerimento }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modalCriarRequerimento" :shift-y="0.1" height="auto" :width="modalWidthMd" :scrollable="true"
      :adaptative="true">
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Novo requerimento</h4>
          <b>{{ fastSecretariaRequerimento.nome_requerimento }}</b>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalCriarRequerimento')">
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Detalhes</label>
              <textarea v-model="fastSecretariaRequerimento.detalhe_requerimento" class="form-control" />
            </div>
            <div v-if="fastSecretariaRequerimento.path_anexo" class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Anexo</label>
              <br>
              <a :href="fastSecretariaRequerimento.path_anexo" target="_blank" download>{{
                fastSecretariaRequerimento.path_anexo }}</a>
            </div>
            <div v-if="fastSecretariaRequerimento.aceita_anexo" class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Upload do anexo</label>
              <uploader :file-status-text="statusText" :options="optionsArquivo" class="uploader-example"
                @file-success="fileSuccessRequerimento" @file-added="fileValidation">
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste um documento aqui ou
                  </p>
                  <uploader-btn :attrs="restrictArquivo">
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group mb-0">
                <button href="#" type="button" class="btn btn-primary" @click.prevent="insereSecretariaRequerimento()">
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modalEditarRequerimento" :shift-y="0.1" height="auto" :width="modalWidthMd" :scrollable="true"
      :adaptative="true">
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Editar requerimento</h4>
          <b>{{ fastSecretariaRequerimentoEditar.nome_requerimento }}</b>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalEditarRequerimento')">
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Detalhes</label>
              <textarea v-model="fastSecretariaRequerimentoEditar.detalhe_requerimento" class="form-control" />
            </div>
            <div v-if="fastSecretariaRequerimentoEditar.path_anexo" class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Anexo</label>
              <br>
              <a :href="fastSecretariaRequerimentoEditar.path_anexo" target="_blank" download>{{
                fastSecretariaRequerimentoEditar.path_anexo }}</a>
            </div>
            <div v-if="fastSecretariaRequerimentoEditar.aceita_anexo" class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Upload do anexo</label>
              <uploader :file-status-text="statusText" :options="optionsArquivo" class="uploader-example"
                @file-success="fileSuccessRequerimentoEditar" @file-added="fileValidation">
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste um documento aqui ou
                  </p>
                  <uploader-btn :attrs="restrictArquivo">
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group mb-0">
                <button href="#" type="button" class="btn btn-primary" @click.prevent="editarSecretariaRequerimento()">
                  Atualizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modalExcluirTipoRequerimento" :scrollable="true" height="auto" :shift-y="0.1" :focus-trap="true"
      :adaptive="true">
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir requerimento?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a class="btn btn-secondary" href="#" @click.prevent="hideModal('modalExcluirTipoRequerimento')">
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button href="#" type="button" class="btn btn-danger" @click.prevent="excluirSecretariaRequerimento()">
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
  
<script>
import { settings } from "@/config";
import methods from "@/methods";
// Descomentar componente
import Pagination from "../../components/Pagination.vue";

export default {
  // Nome do componente
  name: "HomeInternoAlunoMeusRequerimentos",
  // Componentes
  components: {
    Pagination,
  },
  // Carrega métodos globais
  mixins: [methods],
  // Variáveis locais
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastCarregandoAcao: false,
      fastTemplate: settings.fastTemplate,
      fastRequerimentosTotal: [],
      fastTipoRequerimentos: [],
      fastRequerimentoFiltro: [],
      fastRequerimentosPagination: [],
      fastRequerimentosLoading: true,
      fastRequerimentoDetalhes: {
        aceita_anexo: "",
        aprovada: null,
        boleto_gerado: null,
        data_aprovacao: null,
        data_entrega: null,
        data_fim_analise: null,
        data_inicio_analise: null,
        data_pagto: null,
        data_registro: null,
        data_rejeicao: null,
        desconto: 0,
        detalhe_requerimento: "",
        em_analise: null,
        id_pessoa_aluno: 0,
        id_plataforma: 0,
        id_registro_requerimento: 0,
        id_tipo_requerimento: 0,
        id_usuario_aprovador: 0,
        id_usuario_registro: 0,
        nome_requerimento: "",
        notifica_responsavel: false,
        obs: "",
        obs_aprovador: null,
        pago: null,
        path_anexo: "",
        path_boleto: null,
        preco_servico: "",
        solicitado: null,
        taxa: 0,
        total_requerimento: 0,
        valor_requerimento: 0,
      },
      fastFiltro: {
        nomeRequerimento: [],
        nomeRequerimentoEscolhido: "",
        statusRequerimento: [],
        statusRequerimentoEscolhido: "",
      },
      fastSecretariaRequerimento: {
        id_plataforma: 0,
        id_tipo_requerimento: 0,
        detalhe_requerimento: "",
        path_anexo: "",
        aceita_anexo: "",
        nome_requerimento: "",
      },
      fastSecretariaRequerimentoEditar: {
        id_plataforma: 0,
        id_tipo_requerimento: 0,
        detalhe_requerimento: "",
        path_anexo: "",
        aceita_anexo: "",
        nome_requerimento: "",
      },
      // Upload arquivo
      optionsArquivo: {
        target:
          settings.endApiFastEad + "api/fast_plataforma_aluno_documento/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_pessoa_aluno: this.$store.state.fastUsuario.Id_pessoa,
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
    };
  },
  // Carrega componente de forma assíncrona
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  // Após carregar componente
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      // Carrega dependências do aluno, valida plataforma, verifica se usuário está ativo, etc
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          console.log("this.$store.state.fastPermissoes", this.$store.state.fastPermissoes)
          // Iniciar aqui
          this.getRequerimentos(this.$route.params.id_plataforma);
          this.getTipoRequerimentos(this.$route.params.id_plataforma);
          this.$store.state.fastCarregando = false;

        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  // Espaço reservado para os métodos
  methods: {
    async getRequerimentos(id_plataforma, id_pessoa_aluno) {
      this.promiseGetFastApi(
        "api/fast_secretaria_requerimento/aluno/lista",
        "id_plataforma=" +
        id_plataforma
      )
        .then((r) => {
          console.log("detalhesRequerimento", r);
          this.fastRequerimentosTotal = r;
          this.fastRequerimentoFiltro = r;
          this.fastRequerimentosLoading = false;

          // Retorna os nomes de requerimento e o status e elimina duplicados
          this.fastFiltro.nomeRequerimento = Array.from(
            new Set(
              r.map((requerimento) => {
                return requerimento.nome_requerimento;
              })
            )
          );
          this.fastFiltro.statusRequerimento = Array.from(
            new Set(
              r.map((requerimento) => {
                return requerimento.aprovada;
              })
            )
          );
        })
        .catch((e) => {
          this.fastRequerimentosLoading = false;
        });
    },
    exibeModalDetalhesRequerimento(requerimento) {
      console.log("exibeModalDetalhesRequerimento", requerimento);
      this.fastRequerimentoDetalhes = requerimento;
      this.showModal("exibeModalDetalhesRequerimento");
    },
    aplicaFiltro() {
      if (this.fastFiltro.nomeRequerimentoEscolhido) {
        this.fastRequerimentoFiltro = this.fastRequerimentosTotal.filter(
          (r) => {
            return (
              this.fastFiltro.nomeRequerimentoEscolhido == r.nome_requerimento
            );
          }
        );
      } else {
        this.fastRequerimentoFiltro = this.fastRequerimentosTotal;
      }

      if (this.fastFiltro.statusRequerimentoEscolhido) {
        this.fastRequerimentoFiltro = this.fastRequerimentoFiltro.filter(
          (r) => {
            return this.fastFiltro.statusRequerimentoEscolhido == r.aprovada;
          }
        );
      }
    },
    limparFiltro() {
      this.fastFiltro.nomeRequerimentoEscolhido = "";
      this.fastFiltro.statusRequerimentoEscolhido = "";
      this.aplicaFiltro();
    },
    uploadFinalizou(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastSecretariaRequerimento.path_anexo = result.url;
      }
    },
    // Criação/edição requerimento para aluno

    async getTipoRequerimentos(id_plataforma) {
      this.promiseGetFastApi(
        "api/fast_secretaria_tipo_requerimento/lista",
        "id_plataforma=" + id_plataforma
      )
        .then((tipoRequerimentos) => {
          this.fastTipoRequerimentos = tipoRequerimentos;
        })
        .catch((e) => this.exibeToasty(e, "error"));
    },
    validaTipoRequerimento(e) {
      if (e.target.value) {
        this.fastTipoRequerimentos.forEach((t) => {
          if (t.id_tipo_requerimento == e.target.value) {
            this.fastSecretariaRequerimento.id_tipo_requerimento =
              t.id_tipo_requerimento;
            this.fastSecretariaRequerimento.nome_requerimento =
              t.nome_requerimento;
            this.fastSecretariaRequerimento.aceita_anexo = t.aceita_anexo;
          }
        });

        this.showModal(
          "modalCriarRequerimento",
          "modalSelecionaTipoRequerimento"
        );
      } else {
        this.exibeToasty("Selecione um tipo de requerimento", "error");
      }
    },
    async insereSecretariaRequerimento() {
      this.fastSecretariaRequerimento.id_plataforma = this.$route.params.id_plataforma;

      let erros = [];
      if (!this.fastSecretariaRequerimento.id_tipo_requerimento)
        erros.push("O tipo de requerimento é obrigatório");
      if (!this.fastSecretariaRequerimento.detalhe_requerimento)
        erros.push("Os detalhes do requerimento são obrigatórios");

      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      } else {
        this.promisePostFastApi(
          this.fastSecretariaRequerimento,
          "api/fast_secretaria_requerimento/aluno/insere"
        )
          .then((res) => {
            console.log(res)
            this.exibeToasty("Requerimento solicitado com sucesso", "success");
            this.getRequerimentos(this.$route.params.id_plataforma);
            this.hideModal("modalCriarRequerimento");
            this.fastSecretariaRequerimento = {
              id_plataforma: 0,
              id_tipo_requerimento: 0,
              detalhe_requerimento: "",
              path_anexo: "",
              aceita_anexo: "",
              nome_requerimento: "",
            };
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
          });
      }
    },
    fileValidation(e) {
      return false;
    },
    fileSuccessRequerimento(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastSecretariaRequerimento.path_anexo = result.url;
      }
    },
    fileSuccessRequerimentoEditar(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastSecretariaRequerimentoEditar.path_anexo = result.url;
      }
    },
    exibeModalEditarRequerimento(requerimento) {
      console.log(requerimento)
      this.fastSecretariaRequerimentoEditar = requerimento;
      this.showModal("modalEditarRequerimento");
    },
    async editarSecretariaRequerimento() {
      let erros = [];
      if (!this.fastSecretariaRequerimentoEditar.id_tipo_requerimento)
        erros.push("O tipo de requerimento é obrigatório");
      if (!this.fastSecretariaRequerimentoEditar.detalhe_requerimento)
        erros.push("Os detalhes do requerimento são obrigatórios");

      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      } else {
        this.promisePostFastApi(
          this.fastSecretariaRequerimentoEditar,
          "api/fast_secretaria_requerimento/aluno/atualiza"
        )
          .then((res) => {
            this.exibeToasty("Requerimento solicitado com sucesso", "success");
            this.hideModal("modalEditarRequerimento");
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
          });
      }
    },
    exibeModalExcluirRequerimento(requerimento) {
      this.fastSecretariaRequerimentoEditar = requerimento;
      this.showModal("modalExcluirTipoRequerimento");
    },
    async excluirSecretariaRequerimento() {
      this.promisePostFastApi(this.fastSecretariaRequerimentoEditar, "api/fast_secretaria_requerimento/aluno/exclui").then(e => {
        this.exibeToasty("Requerimento excluído com sucesso", "success");
        this.getRequerimentos(this.$route.params.id_plataforma);
        this.hideModal("modalExcluirTipoRequerimento");
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    }
  },
};
</script>
  
<style scope></style>